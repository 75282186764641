import { useMemo } from "react"
import useMediaQuery from "./useMediaQuery"

export type Breakpoint = "2xs" | "xs" | "sm" | "md" | "lg" | "ds" | "xl" | "2xl" | "3xl"

export const BREAKPOINTS = new Map<Breakpoint, number>([
  ["2xs", 320],
  ["xs", 480],
  ["sm", 576],
  ["md", 768],
  ["lg", 992],
  ["ds", 1024],
  ["xl", 1200],
  ["2xl", 1400],
  ["3xl", 1600],
])

/**
 * This hook is used to get the breakpoints of the current screen size.
 * It returns an object with the breakpoints as keys and the value is a boolean
 * indicating if the screen size is greater than or equal to the breakpoint.
 * The breakpoints are aligned with those defined in tailwind.config.js.
 *
 * Usage:
 * const { isDesktop, isTablet, isMobile, ...breakpoints } = useBreakpoints();
 */
export default function useBreakpoints() {
  const xxxl = useMediaQuery("(min-width: 1600px)")
  const xxl = useMediaQuery("(min-width: 1440px)")
  const xl = useMediaQuery("(min-width: 1200px)")
  const ds = useMediaQuery("(min-width: 1024px)")
  const lg = useMediaQuery("(min-width: 992px)")
  const md = useMediaQuery("(min-width: 768px)")
  const sm = useMediaQuery("(min-width: 576px)")
  const xs = useMediaQuery("(min-width: 480px)")
  const xxs = useMediaQuery("(max-width: 479px)")

  // map to tailwind.config.js screens
  const breakpoints = useMemo(() => {
    return {
      "3xl": xxxl,
      "2xl": xxl,
      xl,
      ds,
      lg,
      md,
      sm,
      xs,
      "2xs": xxs,
      isMeasured: xxxl || xxl || xl || lg || ds || md || sm || xs || xxs,
      isDesktop: xxxl || xxl || xl || lg || ds,
      isTablet: !xxxl && !xxl && !xl && !lg && !ds && md,
      isMobile: !xxxl && !xxl && !xl && !lg && !ds && !md && (sm || xs || xxs),
    }
  }, [xxxl, xxl, xl, lg, ds, md, sm, xs, xxs])

  return breakpoints
}
