import { Customer } from "db"

export enum GoogleIntegrationStatus {
  Connected = "Connected",
  Disconnected = "Disconnected",
}

export type GoogleIntegrationData = {
  email: string
  status: GoogleIntegrationStatus
}

export type GoogleDbTokens = Pick<
  Customer,
  | "googleConnectedEmail"
  | "googleOAuthAccessToken"
  | "googleOAuthRefreshToken"
  | "googleIntegrationFailedAttempts"
>

export enum CalendarConnectionCallbackStatus {
  ErrorUserNotFound = "errorUserNotFound",
  ErrorEmailsDoNotMatch = "errorEmailsDoNotMatch",
  ErrorMissingScopes = "errorMissingScopes",
  ErrorUnknown = "errorUnknown",
  Ok = "ok",
}
