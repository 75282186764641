import { useQuery } from "@blitzjs/rpc"
import { useMemo, createContext, useContext, useEffect } from "react"
import { CustomerExtended, GroupExtended, QueryParams, UserExtended } from "@Types/common"
import { SocketEventTypes } from "app/sockets/types"
import getCurrentCustomer from "app/customer/queries/get-current-customer"
import getCurrentGroup from "app/group/queries/get-current-group"
import getGlobalSettings from "app/management/settings/queries/get-global-settings"
import getCurrentUser from "app/users/queries/get-current-user"
import { useSocketEvent } from "app/sockets/useSocketEvent"
import { useRouter } from "next/router"
import { calendarConnectionCallbackStatusToMessage } from "app/google/constants"
import toast from "@Components/ui/Toast"
import { CalendarConnectionCallbackStatus } from "app/google/types"
import { removeQueryParam } from "@Utils/removeQueryParam"
import { SettingsByName } from "@Components/Management/Settings/types"

export interface GlobalContextValue {
  user: UserExtended | null
  customer: CustomerExtended | null
  group: GroupExtended | null
  settings: SettingsByName | null
  refetchUser: () => Promise<void>
  refetchCustomer: () => Promise<void>
  refetchGroup: () => Promise<void>
  refetchSettings: () => Promise<void>
}

export const GlobalContext = createContext<GlobalContextValue>({
  user: null,
  customer: null,
  group: null,
  settings: null,
  refetchUser: () => Promise.resolve(),
  refetchCustomer: () => Promise.resolve(),
  refetchGroup: () => Promise.resolve(),
  refetchSettings: () => Promise.resolve(),
})

function useGlobalContextProvider() {
  const router = useRouter()
  const { query } = router

  const [user, { refetch: refetchUser }] = useQuery(getCurrentUser, {})
  const [customer, { refetch: refetchCustomer }] = useQuery(getCurrentCustomer, {})
  const [group, { refetch: refetchGroup }] = useQuery(getCurrentGroup, {})
  const [settings, { refetch: refetchSettings }] = useQuery(getGlobalSettings, {})

  useSocketEvent(SocketEventTypes.RefetchCustomer, refetchCustomer)

  // re-direction after calendar connection can happen back to almost any page so we need to handle it here
  useEffect(() => {
    if (query[QueryParams.CalendarConnectionCallbackStatus]) {
      const status = query[
        QueryParams.CalendarConnectionCallbackStatus
      ] as CalendarConnectionCallbackStatus
      const message = calendarConnectionCallbackStatusToMessage[status]

      removeQueryParam(router, QueryParams.CalendarConnectionCallbackStatus)

      // without this setTimeout the toast doesn't disappear, no idea why, I don't have time to search for proper solution :(
      setTimeout(() => {
        if (status === CalendarConnectionCallbackStatus.Ok) {
          toast.success(message)
          return
        }

        toast.error(message)
      }, 500)
    }
  }, [query, router])

  const contextValue = useMemo((): GlobalContextValue => {
    return {
      user,
      customer,
      group,
      settings,
      refetchUser: async () => {
        await refetchUser()
      },
      refetchCustomer: async () => {
        await refetchCustomer()
      },
      refetchGroup: async () => {
        await refetchGroup()
      },
      refetchSettings: async () => {
        await refetchSettings()
      },
    }
  }, [user, customer, group, settings, refetchUser, refetchCustomer, refetchGroup, refetchSettings])

  return contextValue
}

export function useGlobalContext() {
  const context = useContext(GlobalContext)
  return context
}

type Props = React.PropsWithChildren<{}>

export const GlobalContextProvider: React.FC<Props> = ({ children }) => {
  const contextValue = useGlobalContextProvider()

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
}
